<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-lg-12">
                <div class="darmin card header-card theme-form">
                    <div class="row">
                        <div class="col">
                            <div class="row m-0 p-0 g-0">
                                <label class="col-md-3 col-form-label">Especialista</label>
                                <div class="col-md-9">
                                    <Autocomplete
                                        inputClass=""
                                        :debounce="1000"
                                        @onInput="getEspecialistas"
                                        :items="especialistas"
                                        :displayItem="
                                            (item) =>
                                                typeof item === 'string' ? item : item.fullName
                                        "
                                        placeholder="Buscar un Especialista"
                                        @onSelect="onSelectEspecialista"
                                        ref="especialistaAutocomplete"
                                    ></Autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Calendar
            :view="calendarViewMode"
            :especialista="especialista"
            :intervalsDisabled="intervalsDisabled"
            :events="events"
            @onEventClick="handleEventClick"
            @onGetIntervalDisabled="handleGetIntervalDisabled"
            @onGetCitas="handleGetCitas"
            @onEventCloseClick="handleEventCloseClick"
            @onViewEventClick="handleViewEventClick"
            @onCancelEventClick="handleCancelEventClick"
            @click.stop="closeAllEvents"
            @onResizeWindows="handleResizeWindows"
            ref="calendar"
            :key="calendarKey"
            :hourHeight="hourHeight"
        />
    </WindowLayout>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import WindowLayout from '@/layouts/Window.layout.vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import Calendar from '../../../components/calendar/calendar.vue';
import useIntervalDisabled from './services/useIntervalDisabled';
import { useCitas } from './services/useCitas';
import { useCita } from '../Citas/services/useCita';
import useDoctor from '../Doctores/services/useDoctor';
import useResize from '../../../services/useResize';

export default {
    name: 'Agenda',
    components: { WindowLayout, Calendar, Autocomplete },
    props: {
        title: { type: String, default: 'Agenda' },
        headerTitle: { type: String, default: 'Calendario de la Semana' },
        hourHeight: {
            type: Number,
            default: 100,
        },
    },
    setup() {
        const store = useStore();
        const calendarViewMode = ref('day');
        const hourMin = ref();
        const hourMax = ref();
        const calendar = ref();
        const calendarKey = ref(Date.now());
        const especialista = ref();

        const {
            intervalsDisabled,
            getIntervalDisabled,
            buildFilters: intervalsDisabledFilter,
        } = useIntervalDisabled();
        const { citas: events, getCitas, buildFilters: citasFilter } = useCitas();
        const { cancelCita } = useCita();
        const { doctores: especialistas, getDoctores: getEspecialistas } = useDoctor();
        const { width } = useResize();

        const especialistaAutocomplete = ref(); // autocomplete input
        const especialistaSelected = ref();
        const onSelectEspecialista = (doctor) => {
            console.log(doctor.id);
            especialista.value = doctor.id;
            especialistaAutocomplete.value.setText(doctor.fullName);
            especialistaSelected.value = doctor;
            calendarKey.value = Date.now();
        };

        const handleGetIntervalDisabled = async () => {
            let filters;
            switch (calendarViewMode.value) {
                case 'week':
                default:
                    filters = intervalsDisabledFilter(
                        undefined,
                        undefined,
                        undefined,
                        // eslint-disable-next-line comma-dangle
                        especialista.value
                    );
                    break;
            }
            await getIntervalDisabled(filters);
        };
        const handleGetCitas = async (currentStart) => {
            let filters;
            switch (calendarViewMode.value) {
                case 'week':
                default:
                    filters = citasFilter(
                        currentStart,
                        // eslint-disable-next-line comma-dangle
                        currentStart.plus({ week: 1 }),
                        // eslint-disable-next-line no-undef
                        undefined,
                        // eslint-disable-next-line comma-dangle
                        especialista.value
                    );
                    break;
            }
            await getCitas(filters, 1000, 1);
        };

        const closeAllEvents = async () => {
            events.value.forEach((e) => {
                e.isOpen = false;
            });
        };
        const handleEventClick = async (item) => {
            const index = events.value.findIndex((e) => e.id === item.id);
            await closeAllEvents();
            events.value[index].isOpen = true;
        };
        const handleEventCloseClick = () => {
            closeAllEvents();
        };
        const handleViewEventClick = (item) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'CitaForm',
                id: `CitaForm_${item.id}`,
                name: 'Cita programada',
                params: {
                    name: 'Cita programada',
                    headerTitle: 'Editar Cita',
                    item,
                },
            });
        };
        /**
         * TODO
         * Mejorar la relacion de esta funcion con el hijo
         * el problema es que cuando emitimos el evento en el hijo no podemos esperar
         * a que se resuelva en el padre para ejecutar la funcion en el hijo y llegar hasta
         * el padre.
         * Como solucion momentanea estamos usando una referencia para llamar
         * a la funcion desde el padre, lo cual considero que no es la mejor practica
         */
        const handleCancelEventClick = async (item) => {
            await cancelCita(item.id);
            calendar.value.handleGetData();
        };

        const handleResizeWindows = (widthVal) => {
            if (widthVal < 576) {
                if (calendarViewMode.value !== 'day') {
                    calendarViewMode.value = 'day';
                }
            } else if (calendarViewMode.value !== 'week') {
                calendarViewMode.value = 'week';
            }
        };

        watch(
            () => width.value,
            (newVal) => {
                handleResizeWindows(newVal);
                // eslint-disable-next-line comma-dangle
            }
        );

        onBeforeMount(() => {
            handleResizeWindows(width);
        });

        return {
            calendarViewMode,
            hourMin,
            hourMax,
            intervalsDisabled,
            getIntervalDisabled,
            events,
            getCitas,
            citasFilter,
            handleEventClick,
            handleEventCloseClick,
            handleViewEventClick,
            handleGetIntervalDisabled,
            handleGetCitas,
            closeAllEvents,
            handleCancelEventClick,
            calendar,
            calendarKey,
            especialista,
            especialistas,
            getEspecialistas,
            especialistaAutocomplete,
            especialistaSelected,
            onSelectEspecialista,
            handleResizeWindows,
        };
    },
};
</script>

<style scoped></style>
